import React, { useEffect, useRef } from 'react';

const RulesModal = ({ setShowRules }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowRules(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="overlay">
      <div className="rules-modal" ref={modalRef}>
        <h2>Trait Combination Rules</h2>
        <ul>
          <li><strong>Laser Eyes</strong> ≠ Gold Seiyan, Red Seiyan, Blunt, Bone, Cigar, Flame, Golden Bone, Paint Brush, Mustache, Trident</li>
          <li><strong>Decoder Goggles</strong> ≠ Red Partyhat, Seilor Hat, Red Seiyan, Gold Seiyan, Chef Hat, Pirate Hat</li>
          <li><strong>Crown</strong> ≠ Decoder Goggles, Ellie's Shades, Robin Mask, Sunglasses, Laser Eyes</li>
          <li><strong>3D Glasses</strong> ≠ Pirate Hat, Seilor Hat, Crown</li>
          <li><strong>Red Partyhat</strong> ≠ Ellie's Shades</li>
		  <li><strong>Curated - TOSHI Mask</strong> ≠ Decoder Goggles, Sunglasses, 3D Glasses</li>
        </ul>
        <button onClick={() => setShowRules(false)}>Close</button>
      </div>
    </div>
  );
};

export default RulesModal;
