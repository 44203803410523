import React from 'react';

const Header = () => {
  return (
    <div className="header">
      <a href="https://craftycanines.xyz" target="_blank" rel="noopener noreferrer">
        Crafty Canine Art Generator
      </a>
    </div>
  );
};

export default Header;
