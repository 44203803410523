import React from 'react';
import PalletIcon from '../icons/pallet.png';
import DiscordIcon from '../icons/discord.png';
import TwitterIcon from '../icons/twitter.png';
import MediumIcon from '../icons/medium.png';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="links-container">
        <p>
          <a href="https://pallet.exchange/collection/crafty-canines" target="_blank" rel="noopener noreferrer" className="btn-link">
            <img src={PalletIcon} alt="Pallet" className="social-icon" />
            <span>Buy Now on Pallet</span>
          </a>
        </p>
        <p>
          <a href="https://discord.com/invite/NRmRcmJV6w" target="_blank" rel="noopener noreferrer" className="btn-link">
            <img src={DiscordIcon} alt="Discord" className="social-icon" />
            <span>Join our Discord</span>
          </a>
        </p>
        <p>
          <a href="https://x.com/crafty_canines" target="_blank" rel="noopener noreferrer" className="btn-link">
            <img src={TwitterIcon} alt="Twitter" className="social-icon" />
            <span>Follow us on Twitter/X</span>
          </a>
        </p>
        <p>
          <a href="https://medium.com/@craftycanines" target="_blank" rel="noopener noreferrer" className="btn-link">
            <img src={MediumIcon} alt="Medium" className="social-icon" />
            <span>Follow us on Medium</span>
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
